import "./Skillcard.css"

const SkillCard = ({ title, iconUrl, isActive, onClick, institution }) => {
    return (
        <div className={`skills-card ${isActive ? "active" : ""}`} onClick={onClick}>
            <div className="skill-icon">
                {iconUrl}
            </div>
            <span>{title}</span>
            {isActive && <div className="skill-institution">{institution}</div>}
        </div>
    );
};

 
export default SkillCard;