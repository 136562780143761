// Packages
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components

// Styling
import { FaInstagram, FaLinkedin, FaTiktok, FaFacebook, FaSnapchat } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer-container">
      <div className="footer-links">
        <Link to="/">{t('home')}</Link>
        <Link to="/forum">{t('forum')}</Link>
        <Link to="/about">{t('about')}</Link>
        <Link to="/contact">{t('contact')}</Link>
      </div>
      
      <p><Link to="/login">{t('footer')}</Link></p>
      <p>{t('web')} <a className="developer-link" href="https://tareqelsayyed.onrender.com/" target="_blank" rel="noopener noreferrer">{t('tareq')}</a></p>
      
      <div className="social-links">
        <a href="https://www.instagram.com/dr.omarmulki?igsh=dWZrcTBvc3B1ZnM3" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
        <a href="https://www.linkedin.com/in/omar-mulki-47a47110/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
        <a href="https://www.tiktok.com/@dr.omar.mulki?_t=8m8IrXDWkci&_r=1" aria-label="Instagram" target='_blank' rel="noreferrer">
          <FaTiktok />
        </a>
        <a href="https://www.facebook.com/profile.php?id=61559189732244" aria-label="Instagram" target='_blank' rel="noreferrer">
          <FaFacebook />
        </a>
        <a href="https://www.snapchat.com/add/dromarmulki?share_id=ur0AsEYIRli6rBUG7SR8tw&locale=en_AE" aria-label="Instagram" target='_blank' rel="noreferrer">
          <FaSnapchat />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
