import React from 'react';
import { useTranslation } from 'react-i18next';

import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';

import './About.css'; 

const About = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div className="about-container">
        <section className="about-hero">
          <h1>{t('name')}</h1>
          <p>{t('brief')}</p>
        </section>
        <section className="about-details">
          <div className="about-bio">
            <h2>{t('bio')}</h2>
            <p> {t('about_1')}</p>
            <br />
            <p>{t('about_2')}</p>
            <p>{t('about_3')}</p>
            <p>{t('about_4')}</p>
            <p>{t('about_5')}</p>
            </div>
          <div className="about-qualifications">
            <h2>{t('qualifications')}</h2>
            <ul>
              <li>{t('about_7')}</li>
              <li>{t('about_8')}</li>
              <li>{t('about_9')}</li>
              <li>{t('about_10')}</li>
              <li>{t('about_11')}</li>
              <li>{t('about_12')}</li>
            </ul>

          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default About;
