// Packages
import { useState } from "react";
import { useTranslation } from 'react-i18next';
// Components
import loaderGif from "../../assets/loader.gif";
// Styles
import "./Contact.css";

const Contact = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [sending, setSending] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSending(true);
        setError(null);

        try {
            const response = await fetch('https://dr-omar-api.onrender.com/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, message }),
            });

            const data = await response.json();
            if (response.ok) {
                setEmail('');
                setMessage('');
            } else {
                setError(data.message || t('failed_to_send_message'));
            }
        } catch (err) {
            setError(t('failed_to_send_message'));
        } finally {
            setSending(false);
        }
    };

    return (
        <>
            {sending && (
                <div className="loader-overlay">
                    <img src={loaderGif} alt="Loading..." className="loader" />
                </div>
            )}
            <section className="contact-container">
                <h5>{t('get_in_touch')}</h5>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <input
                        type="email"
                        placeholder={t('your_email')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <textarea
                        placeholder={t('your_message')}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    ></textarea>
                    <button type="submit" disabled={sending}>
                        {t('send_message')}
                    </button>
                    {error && <div className="error">{error}</div>}
                </form>
            </section>
        </>
    );
};

export default Contact;
