// Packages
import { useState } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import Mobilenav from "../Mobilenavbar/Mobilenav";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
// Styling
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import Logo from "../../assets/DrOmarLogoCropped.svg";
import "./Navbar.css";

const Navbar = () => {
    const { t } = useTranslation();
    const [openMenu, setOpenMenu] = useState(false);
    const toggleMenu = () => {
        setOpenMenu(!openMenu);
    };

    return (
        <>
            <Mobilenav isOpen={openMenu} toggleMenu={toggleMenu} />
            <nav className="nav-wrapper">
                <div className="nav-content">
                    <div className="nav-logo">
                        <Link to="/">
                            <img src={Logo} alt="logo" />
                        </Link>
                    </div>

                    <ul>
                        <li>
                            <Link to="/" className="menu-item">{t('home')}</Link>
                        </li>
                        <li>
                            <Link to="/about" className="menu-item">{t('about')}</Link>
                        </li>
                        <li>
                            <Link to="/forum" className="menu-item">{t('forum')}</Link>
                        </li>
                        <li>
                            <Link to="/faq" className="menu-item">{t('faq')}</Link>
                        </li>
                        <li>
                            <Link to="/contact" className="contact-btn">{t('contact')}</Link>
                        </li>
                    </ul>
                    <LanguageSwitcher />
                    <button className="menu-btn" onClick={toggleMenu}>
                        <span>
                            {openMenu ? <IoClose /> : <GiHamburgerMenu />}
                        </span>
                    </button>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
