import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import { FAQS } from "../utils/data";
import "./FAQ.css";

const FAQ = () => {
  const { t } = useTranslation();
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  const toggleAnswer = (index) => {
    if (expandedQuestion === index) {
      setExpandedQuestion(null); 
    } else {
      setExpandedQuestion(index); 
    }
  };

  return (
    <>
      <Navbar />
      <div className="faq-page">
        <h2>{t('faqs')}</h2>
        <div className="faq-list">
          {FAQS.map((faq, index) => (
            <div key={index} className="faq-item">
              <div className="faq-question-container" onClick={() => toggleAnswer(index)}>
                <h3 className="faq-question">
                  {t(faq.question)}
                </h3>
                <span className="faq-icon">
                  {expandedQuestion === index ? '↓' : '+'}
                </span>
              </div>
              {expandedQuestion === index && (
                <>
                  <div className="faq-separator"></div>
                  <p className="faq-answer">{t(faq.answer)}</p>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FAQ;
