// Packages
import React from 'react';
import { useTranslation } from 'react-i18next';
// Components
import Contact from '../components/Contact/Contact';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import { FaInstagram, FaLinkedin, FaTiktok, FaFacebook, FaSnapchat } from 'react-icons/fa';
// Styling
import './ContactPage.css';

const ContactPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <div className="contact-page">
        <div className="contact-form-section">
          <Contact />
        </div>
        <div className="contact-info-section">
          <div className="contact-information">
            <h2>{t('contact_information')}</h2>
            <p>{t('phone')}: +971 56 150 0596</p>
            <p>{t('email')}: dromarmulki@gmail.com</p>
            <div className="social-media-links">
              <p>{t('find_us_on_social_media')}</p>
              <div className="social-icons">
                <a href="https://www.linkedin.com/in/omar-mulki-47a47110/" aria-label="LinkedIn" target='_blank' rel="noreferrer">
                  <FaLinkedin />
                </a>
                <a href="https://www.instagram.com/dr.omarmulki?igsh=dWZrcTBvc3B1ZnM3" aria-label="Instagram"  target='_blank' rel="noreferrer">
                  <FaInstagram />
                </a>
                <a href="https://www.tiktok.com/@dr.omar.mulki?_t=8m8IrXDWkci&_r=1" aria-label="TikTok" target='_blank' rel="noreferrer">
                  <FaTiktok />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61559189732244" aria-label="Facebook" target='_blank' rel="noreferrer">
                  <FaFacebook />
                </a>
                <a href="https://www.snapchat.com/add/dromarmulki?share_id=ur0AsEYIRli6rBUG7SR8tw&locale=en_AE" aria-label="Snapchat" target='_blank' rel="noreferrer">
                  <FaSnapchat />
                </a>
              </div>
            </div>
          </div>
          <div className="location-map">
            <h2>{t('location')}</h2>
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14551.505619399066!2d55.7241925!3d24.2461012!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8ab3cfff807fe5%3A0x7e84a0642992f058!2sAin%20Al%20Khaleej%20Hospital!5e0!3m2!1sen!2slb!4v1712994677547!5m2!1sen!2slb"
              width="600" 
              height="450" 
              style={{ border: 0 }} 
              allowFullScreen=""
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade"
              title={t('location')}
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
