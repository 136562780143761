import React from 'react';
import { Link } from 'react-router-dom'; 
import "./NotFound.css"

const NotFoundPage = () => {
    return (
        <div className="not-found-container">
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
            <Link to="/" className="home-link">Go to Homepage</Link>
        </div>
    );
}

export default NotFoundPage;
