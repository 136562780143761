// Packages
import { useTranslation } from 'react-i18next';
// Components
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import Hero from '../components/Hero/Hero';
import Skills from '../components/Skills/Skills';
import Workexperience from '../components/WorkExperience/Workexperience';
// Styling



const Home = () => {
  const { t } = useTranslation();
  return (
    <main>
        <Navbar />
        <Hero />
        <Skills />
        <Workexperience />
        <Footer />
    </main>
  );
};

export default Home;
