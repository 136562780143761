// Packages
import { useState } from "react";
import { useTranslation } from 'react-i18next';
// Components
import Skillcard from "./Skillcard/Skillcard";
// Data
import { ACHIEVEMENTS } from "../../utils/data";
// Styling
import "./Skills.css";

const Skills = () => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(null);
    return (
        <section className="skills-container">
            <h5>{t('qualifications')}</h5>
            <div className="skills-content">
                <div className="skills">
                {ACHIEVEMENTS.map((item, index) => (
                    <Skillcard
                        key={index}
                        title={item.title}
                        iconUrl={item.icon}
                        institution={t(item.institution)}
                        isActive={index === activeIndex}
                        onClick={() => setActiveIndex(index === activeIndex ? null : index)}
                    />
                ))}
                </div>
            </div>
        </section>
    );
}

export default Skills;
