// Packages 
import { Link } from 'react-router-dom'
import { useTranslation  } from 'react-i18next';
// Components
import doctorImg from "../../assets/hero_1.jpg";
import icon1 from "../../assets/1.png";
import icon2 from "../../assets/2.png";
import icon3 from "../../assets/3.png";
import icon4 from "../../assets/4.png";
// Styling 
import "./Hero.css";

const Hero = () => {
    const { t } = useTranslation();
    return ( 
        <section className="hero-container">
            <div className="hero-content">
                <h2>{t('name')}</h2>
                <p>{t('hero_text')}</p>
                <Link to='/about'><button className = "hero-btn">{t('learn_more')}</button></Link>
            </div>

            <div className="hero-img">
                <img src={doctorImg} alt="Doctor Image" />
                <div className="icons">
                    <img src={icon1} alt="Icon-1" />
                    <img src={icon2} alt="Icon-2" />
                    <img src={icon3} alt="Icon-3" />
                    <img src={icon4} alt="Icon-4" />
                </div>
            </div>
        </section>
     );
}

export default Hero;
