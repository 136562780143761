import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';

// Pages
import Home from './Pages/Home';
import About from './Pages/About';
import Forum from './Pages/Forum';
import Contact from './Pages/Contact';
import Login from './Pages/Doctor/Login/Login';
import Dashboard from './Pages/Doctor/Dashboard/Dashboard';
import Answered from './Pages/Doctor/Answered/Answered';
import Unanswered from './Pages/Doctor/Unanswered/Unanswered';
import NotFoundPage from './Pages/NotFoundPage';
import FAQ from './Pages/FAQ';
// Styling
import './App.css';
import './index.css'

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await axios.get('https://dr-omar-api.onrender.com/api/loggedin', { withCredentials: true });
        setLoggedIn(response.data.isLoggedIn);
      } catch (error) {
        console.error('Error checking login status:', error);
        console.log();
      }
    };

    checkLoginStatus();
  }, []);

  const ProtectedRoute = ({ children }) => {
    return loggedIn ? children : <Navigate to="/login" />;
  };

  return (
    <div className="container">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/forum" element={<Forum />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={loggedIn ? <Navigate to="/dashboard" /> : <Login setLoggedIn={setLoggedIn} />} />
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard setLoggedIn={setLoggedIn} /></ProtectedRoute>} />
          <Route path="/answered" element={<ProtectedRoute><Answered /></ProtectedRoute>} />
          <Route path="/unanswered" element={<ProtectedRoute><Unanswered /></ProtectedRoute>} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
