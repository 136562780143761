import { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import { EXPERIENCE } from "../../utils/data";
import Experiencecard from "./ExperienceCard/Experiencecard";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import "./Workexperience.css";

const Workexperience = () => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(null); 
    const slideRef = useRef();

    const NextArrow = ({ onClick }) => (
        <div className="arrow arrow-right" onClick={onClick}>
            <HiArrowNarrowRight />
        </div>
    );

    const PrevArrow = ({ onClick }) => (
        <div className="arrow arrow-left" onClick={onClick}>
            <HiArrowNarrowLeft />
        </div>
    );

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    return ( 
        <section className="experience-container">
            <h5>{t('experience')}</h5>
            <div className="experience-cards-container">
                <Slider ref={slideRef} {...settings}>
                    {EXPERIENCE.map((item, index) => (
                        <Experiencecard
                            key={index}
                            title={t(item.title)}
                            icon={item.icon}
                            description={t(item.description)}
                            isActive={index === activeIndex}
                            onClick={() => setActiveIndex(index)}
                        />
                    ))}
                </Slider>
            </div>
        </section>
    );
}

export default Workexperience;
