// Styling
import "./Experiencecard.css"

const Experiencecard = ({ title, icon, description }) => {
    return ( 
        <div className="work-experience-card">
            <div className="experience-icon">
                {icon}
            </div>
            <div className="experience-content">
                <h6 className="experience-title">{title}</h6>
                <p>{description}</p>
            </div>
        </div>
     );
}

export default Experiencecard;
