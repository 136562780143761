import { FaUserMd, FaUserGraduate, FaHospital, FaGlobeEurope, FaSyringe } from 'react-icons/fa';

export const ACHIEVEMENTS = [
    {
        title: "MBBS",
        icon: <FaUserGraduate style={{ width: '35px' }} />,
        institution: "university_1"
    },
    {
        title: "BSc",
        icon: <FaUserGraduate style={{ width: '35px' }} />,
        institution: "university_2"
    },
    {
        title: "MRCOG",
        icon: <FaUserMd style={{ width: '35px' }} />,
        institution: "university_3"
    },
    {
        title: "MEd",
        icon: <FaUserGraduate style={{ width: '35px' }} />,
        institution: "university_4"
    },
    {
        title: "SurgEd",
        icon: <FaSyringe style={{ width: '35px' }} />,
        institution: "university_4"
    },
    {
        title: "DIC",
        icon: <FaUserGraduate style={{ width: '35px' }} />,
        institution: "university_4"
    }
];


export const EXPERIENCE = [
    {
        title: "experience_1_title",
        icon: <FaHospital style={{ width: '35px' }} />,
        description: "experience_1"
    },
    {
        title: "experience_2_title",
        icon: <FaUserMd style={{ width: '35px' }} />,
        description: "experience_2"
    },
    {
        title: "experience_3_title",
        icon: <FaGlobeEurope style={{ width: '35px' }} />,
        description: "experience_3"
    }
];


export const FAQS = [
    {
        question: "question_1",
        answer: "answer_1"
    },
    {
        question: "question_2",
        answer: "answer_2"
    },
    {
        question: "question_3",
        answer: "answer_3"
    },
    {
        question: "question_4",
        answer: "answer_4"
    },
    {
        question: "question_5",
        answer: "answer_5"
    },
    {
        question: "question_6",
        answer: "answer_6"
    },
    {
        question: "question_7",
        answer: "answer_7"
    },
    {
        question: "question_8",
        answer: "answer_8"
    },
    {
        question: "question_9",
        answer: "answer_9"
    },
    {
        question: "question_10",
        answer: "answer_10"
    },
    {
        question: "question_11",
        answer: "answer_11"
    },
    {
        question: "question_12",
        answer: "answer_12"
    },
    {
        question: "question_13",
        answer: "answer_13"
    },
    {
        question: "question_14",
        answer: "answer_14"
    },
    {
        question: "question_15",
        answer: "answer_15"
    },
    {
        question: "question_16",
        answer: "answer_16"
    },
    {
        question: "question_17",
        answer: "answer_17"
    },
    {
        question: "question_18",
        answer: "answer_18"
    },
    {
        question: "question_19",
        answer: "answer_19"
    },
    {
        question: "question_20",
        answer: "answer_20"
    }
];