// Packages
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
// Components
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
// Assets
import loaderGif from '../assets/loader.gif'; 
// Styles
import "./Forum.css";

const Forum = () => {
  const { t } = useTranslation();
  const [questions, setQuestions] = useState([]);
  const [questionText, setQuestionText] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const { data } = await axios.get('https://dr-omar-api.onrender.com/api/questions');
        setQuestions(data);
      } catch (error) {
        setError(t('Fail'));
      }
    };

    fetchQuestions();
  }, []);

  const handleAskQuestion = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true); 
    if (!questionText) {
      setError(t('Please enter a question before submitting.'));
      setLoading(false); 
      return;
    }

    try {
      const { data } = await axios.post('https://dr-omar-api.onrender.com/api/questions', { questionText });
      setQuestions([...questions, data]);
      setQuestionText(''); 
    } catch (error) {
      setError(t('Failed to post question. Please try again later.'));
    } finally {
      setLoading(false); 
    }
  };

  return (
    <>
      <Navbar />
      <div className="forum-page">
        <h1>{t('forum')}</h1>
        <form onSubmit={handleAskQuestion} className="ask-question-form">
          <input
            type="text"
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value)}
            placeholder={t('ask')}
            aria-label={t('ask')}
            disabled={loading} 
          />
          <button type="submit" disabled={loading}> 
            {loading ? <img src={loaderGif} alt="Loading..." /> : t('ASK')}
          </button>
        </form>
        {error && <p className="error">{error}</p>}
        <div className="questions-list">
          {questions.length > 0 ? (
            questions.map((question) => (
              <div key={question._id} className="question-item">
                <h3>{question.questionText}</h3>
                {question.answerText ? (
                  <p className="answer">{question.answerText}</p>
                ) : (
                  <p className="no-answer">{t('Waiting for an answer...')}</p>
                )}
              </div>
            ))
          ) : (
            <p className="no-questions">{t('NO')}</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Forum;
