import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Unanswered.css'; 

const Unanswered = () => {
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUnansweredQuestions = async () => {
      const response = await axios.get('https://dr-omar-api.onrender.com/api/questions/unanswered', { withCredentials: true });
      setQuestions(response.data);
    };

    fetchUnansweredQuestions();
  }, []);

  const handleAnswer = async (id, answer, e) => {
    e.target.reset(); 
    try {
      await axios.post(`https://dr-omar-api.onrender.com/api/questions/answer/${id}`, { answerText: answer }, { withCredentials: true });
      setQuestions(questions.filter((question) => question._id !== id));
    } catch (error) {
      console.error('Error posting answer:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://dr-omar-api.onrender.com/api/questions/${id}`, { withCredentials: true });
      setQuestions(questions.filter((question) => question._id !== id));
    } catch (error) {
      console.error('Error deleting question:', error);
    }
  };

  return (
    <div className="unanswered-container">
      <button onClick={() => navigate('/dashboard')} className="back-button">Back to Dashboard</button>
      <h2>Unanswered Questions</h2>
      {questions.length > 0 ? (
        questions.map((question) => (
          <div key={question._id} className="question-card">
            <p className="question-text">{question.questionText}</p>
            <form onSubmit={(e) => {
              e.preventDefault();
              handleAnswer(question._id, e.target.answer.value, e);
            }} className="answer-form">
              <input type="text" name="answer" placeholder="Type your answer here" className="answer-input" />
              <button type="submit" className="answer-button">Submit Answer</button>
            </form>
            <button onClick={() => handleDelete(question._id)} className="delete-button">Delete</button>
          </div>
        ))
      ) : (
        <p>No unanswered questions available.</p>
      )}
    </div>
  );
};

export default Unanswered;
