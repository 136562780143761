import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Answerd.css'; 

const Answered = () => {
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAnsweredQuestions = async () => {
      const response = await axios.get('https://dr-omar-api.onrender.com/api/questions/answered', { withCredentials: true });
      setQuestions(response.data);
    };

    fetchAnsweredQuestions();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://dr-omar-api.onrender.com/api/questions/${id}`, { withCredentials: true });
      setQuestions(questions.filter((question) => question._id !== id));
    } catch (error) {
      console.error('Error deleting question:', error);
    }
  };

  return (
    <div className="answered-container">
      <button onClick={() => navigate('/dashboard')} className="back-button">Back to Dashboard</button>
      <h2>Answered Questions</h2>
      {questions.length > 0 ? (
        questions.map((question) => (
          <div key={question._id} className="question-card">
            <p className="question-text">{question.questionText}</p>
            <p className="answer-text">Answer: {question.answerText}</p>
            <button onClick={() => handleDelete(question._id)} className="delete-button">Delete</button>
          </div>
        ))
      ) : (
        <p>No answered questions available.</p>
      )}
    </div>
  );
};

export default Answered;
