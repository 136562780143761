import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css'; 

const Dashboard = ({ setLoggedIn, doctorName }) => {
  const [questions, setQuestions] = useState([]);
  const [name, setName] = useState('');
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const { data } = await axios.get('https://dr-omar-api.onrender.com/api/questions', { withCredentials: true });
        setQuestions(data);
      } catch (error) {
        console.error('Error fetching questions:', error);
        navigate('/login');
      }
    };
    const fetchUser = async () => {
      try {
        const { data } = await axios.get('https://dr-omar-api.onrender.com/api/getuser', { withCredentials: true });
        console.log('User data:', data.name);
        setName(data.name);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    }

    fetchUser();
    fetchQuestions();
  }, []);



  const handleLogout = async () => {
    try {
      await axios.get('https://dr-omar-api.onrender.com/api/logout', { withCredentials: true });
      setLoggedIn(false); 
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      setLoggedIn(false); 
      navigate('/login'); 
    }
  };
  
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://dr-omar-api.onrender.com/api/questions/${id}`, { withCredentials: true });
      setQuestions(questions.filter((question) => question._id !== id));
    } catch (error) {
      console.error('Error deleting question:', error);
    }
  };


  const navigateToAnswered = () => navigate('/answered');
  const navigateToUnanswered = () => navigate('/unanswered');
  const navigateToHome = () => navigate('/');
  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <h2>Good Morning, Dr. { name }</h2>
        <nav className="dashboard-nav">
          <button onClick={navigateToAnswered} className="dashboard-button">Answered Questions</button>
          <button onClick={navigateToUnanswered} className="dashboard-button">Unanswered Questions</button>
          <button onClick={navigateToHome} className="dashboard-button">Home Page</button>
          <button onClick={handleLogout} className="dashboard-button">Logout</button>
        </nav>
      </header>
      <section className="questions-container">
      {questions.length > 0 ? (
        questions.map((question) => (
          <article key={question._id} className="question">
            <p className="question-text">{question.questionText}</p>
            {question.answerText ? <p className="answer-text">{question.answerText}</p> : <p>Questions is not answered.</p>}

            <button onClick={() => handleDelete(question._id)} className="delete-button">Delete</button>
          </article>
        ))
      ) : (
        <p>No questions available.</p>
      )}
    </section>



    </div>
  );
};

export default Dashboard;
